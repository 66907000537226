@import "src/variables";

// default styles for main layout:

.cd-main-wrapper {
  margin: auto;
  @media all and (min-width: 1200px) {
    width: 1200px;
  }
}


.main-header {
  position: relative;

  h1 {
    margin-bottom: $innerComponentSpacing;
  }

  h2 {
    margin-bottom: $innerComponentSpacing;
  }

  .intro {
    margin-bottom: $interComponentSpacingSmall;
  }

  .header-cta {
    position: absolute !important;
    top: 0;
    right: 0;
  }
}

// default paddings for column layout:
.col-padding {
  padding: $col-padding;
}

.col-padding-top {
  padding-top: $col-padding-top;
}

.col-padding-bottom {
  padding-bottom: $col-padding-bottom;
}

.col-padding-left {
  padding-left: $col-padding-left;
}

.col-padding-right {
  padding-right: $col-padding-right;
}

.cd-input-padding-left {
  padding-left: calc(#{$input-padding-left} + #{$input-border-width});
}

.cd-input-padding-top {
  padding-top: calc(#{$input-padding-top} + #{$input-border-width});
}

.cd-input-padding {
  padding: calc(#{$input-padding-top} + #{$input-border-width}) calc(#{$input-padding-left} + #{$input-border-width});
  // assure unchanged height, if no value is displayed:
  &::after {
    content: "&nbsp;";
    visibility: hidden;
  }
}

// padding classes
@mixin spacing-factory( $name, $padding ) {
  // padding
  .p-#{$name} {
    padding: $padding;
  }
  .p-#{$name}-t {
    padding-top: $padding;
  }
  .p-#{$name}-r {
    padding-right: $padding;
  }
  .p-#{$name}-r-i {
    padding-right: $padding !important;
  }
  .p-#{$name}-b {
    padding-bottom: $padding;
  }
  .p-#{$name}-b-i {
    padding-bottom: $padding !important;
  }
  .p-#{$name}-l {
    padding-left: $padding;
  }
  .p-#{$name}-l-i {
    padding-left: $padding !important;
  }
  .p-#{$name}-h {
    padding-right: $padding;
    padding-left: $padding;
  }
  .p-#{$name}-v {
    padding-top: $padding;
    padding-bottom: $padding;
  }
  // margin
  .m-#{$name} {
    margin: $padding;
  }
  .m-#{$name}-i {
    margin: $padding !important;
  }
  .m-#{$name}-t {
    margin-top: $padding;
  }
  .m-#{$name}-t-i {
    margin-top: $padding !important;
  }
  .m-#{$name}-r {
    margin-right: $padding;
  }
  .m-#{$name}-r-i {
    margin-right: $padding !important;
  }
  .m-#{$name}-b {
    margin-bottom: $padding;
  }
  .m-#{$name}-b-i {
    margin-bottom: $padding !important;
  }
  .m-#{$name}-l {
    margin-left: $padding;
  }
  .m-#{$name}-l-i {
    margin-left: $padding !important;
  }
  .m-#{$name}-h {
    margin-right: $padding;
    margin-left: $padding;
  }
  .m-#{$name}-v {
    margin-top: $padding;
    margin-bottom: $padding;
  }
}

@include spacing-factory('gutter', $gutterHeight);
@include spacing-factory('gutterSmall', $gutterHeightSmall);
@include spacing-factory('inner', $innerComponentSpacing);
@include spacing-factory('innerSmall', $innerComponentSpacingSmall);
@include spacing-factory('inter', $interComponentSpacing);
@include spacing-factory('interSmall', $interComponentSpacingSmall);
@include spacing-factory('no', 0);

/** CSS Grid System
====================== */
.cd-grid { display: grid; }

// grid column
.cd-cols-1 { grid-template-columns: repeat(1, minmax(0, 1fr)); }
.cd-cols-2 { grid-template-columns: repeat(2, minmax(0, 1fr)); }
.cd-cols-3 { grid-template-columns: repeat(3, minmax(0, 1fr)); }
.cd-cols-4 { grid-template-columns: repeat(4, minmax(0, 1fr)); }
.cd-cols-5 { grid-template-columns: repeat(5, minmax(0, 1fr)); }
.cd-cols-6 { grid-template-columns: repeat(6, minmax(0, 1fr)); }
// ... to be continued when needed

// grid col-span
.cd-col-span-1 { grid-column: span 1 / span 1; }
.cd-col-span-2 { grid-column: span 2 / span 2; }
.cd-col-span-3 { grid-column: span 3 / span 3; }
.cd-col-span-4 { grid-column: span 4 / span 4; }
.cd-col-span-5 { grid-column: span 5 / span 5; }
.cd-col-span-6 { grid-column: span 6 / span 6; }
// ... to be continued when needed

// gap inter component spacing small
.cd-gap-inter-component-spacing-small { gap: $interComponentSpacingSmall; }
.cd-gap-inter-component-spacing-small-x { column-gap: $interComponentSpacingSmall; }
.cd-gap-inter-component-spacing-small-y { row-gap: $interComponentSpacingSmall; }
// gap inter component spacing
.cd-gap-inter-component-spacing { gap: $interComponentSpacing; }
.cd-gap-inter-component-spacing-x { column-gap: $interComponentSpacing; }
.cd-gap-inter-component-spacing-y { row-gap: $interComponentSpacing; }
// gap gutter height small
.cd-gap-gutter-height-small { gap: $gutterHeightSmall; }
.cd-gap-gutter-height-small-x { column-gap: $gutterHeightSmall; }
.cd-gap-gutter-height-small-y { row-gap: $gutterHeightSmall; }
// gap gutter height
.cd-gap-gutter-height { gap: $gutterHeight; }
.cd-gap-gutter-height-x { column-gap: $gutterHeight; }
.cd-gap-gutter-height-y { row-gap: $gutterHeight; }
// gap inner component small
.cd-gap-inner-component-spacing-small { gap: $innerComponentSpacingSmall; }
.cd-gap-inner-component-spacing-small-x { column-gap: $innerComponentSpacingSmall; }
.cd-gap-inner-component-spacing-small-y { row-gap: $innerComponentSpacingSmall; }
// gap inner component
.cd-gap-inner-component-spacing { gap: $innerComponentSpacing; }
.cd-gap-inner-component-spacing-x { column-gap: $innerComponentSpacing; }
.cd-gap-inner-component-spacing-y { row-gap: $innerComponentSpacing; }


/**
 * CSS Flexbox
 */
.cd-flex {
  display: flex;
}

.cd-flex-i {
  display: flex !important;
}
.cd-inline-flex {
  display: inline-flex;
}

.cd-flex-column {
  flex-direction: column;
}

.cd-flex-start {
  align-items: flex-start;
}

.cd-flex-center {
  align-items: center;
}

.cd-flex-align-self-center {
  align-self: center;
}

.cd-flex-align-self-start {
  align-self: flex-start;
}

.cd-flex-baseline-i {
  align-items: baseline !important;
}

.cd-flex-j-center {
  justify-content: center;
}

.cd-flex-j-space-between {
  justify-content: space-between;
}

.cd-flex-j-end {
  justify-content: flex-end;
}

.cd-flex-j-content-start {
  justify-content: start;
}

//noinspection CssRedundantUnit - see https://github.com/philipwalton/flexbugs#flexbug-4
.cd-flex-spacer {
  flex: 1 0 0%;
}

.cd-flex-force-shrink-grow {
  flex: 1 1;
  min-width: 0; // without elements do not shrink past their regular size (https://stackoverflow.com/a/36247448/2544163)
}

/** shrinks element with overflow-x hidden when space is scarce */
.cd-flex-shrink {
  flex: 0 1 auto;
  overflow-x: hidden;
}

.cd-flex-grow {
  flex-grow: 1;
}

.cd-flex-no-grow {
  flex: 0 0 auto;
}

.cd-flex-basis-0 {
  flex-basis: 0;
}

.cd-flex-wrap {
  flex-wrap: wrap;
}

.cd-block {
  display: block;
}

.cd-block-i {
  display: block !important;
}

.cd-inline-block {
  display: inline-block;
}

.cd-none {
  display: none;
}

.cd-none-i {
  display: none !important;
}

.cd-w-100 {
  width: 100%;
}

.cd-w-fit {
  width: fit-content;
}

.cd-w-unset-i {
  width: unset !important;
}

.cd-float-right {
  float: right;
}

.cd-overflow-x-auto {
  overflow-x: auto;
}

/* *
* Default form layouts with multiple columns
* Workaround with flex since IE can't handle a dynamic grid layout
*
* Standard is 2 col - for rows with more than 2 cols use 'cd-col-explicit-margin-right' and 'cd-col-last'
*/
.cd-col-form-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;

  $gap: $formRowGapHorizontal;

  &.cd-col-row-no-margin-top {
    > * {
      margin-top: 0 !important;
    }
  }

  > * {
    width: calc(50% - #{$gap} / 2) !important;
    flex-grow: 0;

    &:nth-child(odd) {
      margin-right: $gap * 0.5;
    }

    &:nth-child(even) {
      margin-left: $gap * 0.5;
    }

    /** use only 1/4 space */
    &.cd-col-small {
      width: calc(25% - 0.75 * #{$gap}) !important;
    }

    /** @deprecated use only 15% space */
    &.cd-col-small-15 {
      width: calc(15% - 0.85 * #{$gap}) !important;
    }

    /** @deprecated use only 35% space */
    &.cd-col-small-35 {
      width: calc(35% - 0.65 * #{$gap}) !important;
    }

    /** use only 3/4 space */
    &.cd-col-large {
      width: calc(75% - 0.25 * #{$gap}) !important;
    }

    &.cd-col-minimal {
      width: unset !important;
    }

    /** use full space */
    &.cd-col-full {
      width: 100% !important;
      margin-right: 0;
    }

    // for non-standard (non 2 col) use:
    &.cd-col-explicit-margin-right {
      margin-right: $gap;
    }

    // for non-standard (non 2 col) use:
    &.cd-col-last {
      margin-right: 0;
    }

    /** use for displaying errors over the full length (two columns): */
    &.cd-error-row {
      width: 100% !important;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.cd-error-no-wrap {
  .sbb-form-field-wrapper {
    .sbb-form-field-error-wrapper {
      overflow: visible !important;
    }
  }

  .sbb-error {
    white-space: nowrap;
  }
}

.cd-error-min-height {
  min-height: $lineHeightSmall;
}

/** sets min-height for a single line editor row to allow first error line to occur without shifting the layout */
.cd-editor-row {
  min-height: $editorRowHeight; // assure first error line does not mess with layout
  width: 100%;
}

.cd-field-icon-container {
  display: flex;
  align-items: start;
  justify-content: center;

  > sbb-icon, > sbb-tooltip {
    margin-top: ($inputHeight - $defaultIconSize) * 0.5;
  }

  sbb-icon[svgicon^="trash-small"]:hover {
    cursor: pointer;

    svg * {
      color: $colorCallToActionHover !important;
    }
  }
}

/** prevent absolute footer overlapping content with margin-bottom: $actionBarHeight on content container  */
.cd-absolute-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100001;

  display: flex;
  align-items: center;
  flex-direction: row;

  width: 100%;

  background-color: var(--sbb-color-background);

  border-top: 1px solid var(--sbb-header-lean-border-bottom-color);

  padding: $innerComponentSpacingSmall $sbbAccordionPaddingHorizontal;

  // default margin between buttons/elements:
  > *:not(:last-child) {
    margin-right: $gutterHeight;
  }
}

/** overwrite padding when using footer in main view to match default margin: */
*[main] .cd-absolute-footer {
  padding-left: $col-gap;
  padding-right: $col-gap;
}

.cd-v-middle {
  vertical-align: middle;
}

.cd-v-middle-i {
  vertical-align: middle !important;
}

.cd-v-bottom-i {
  vertical-align: bottom !important;
}

.cd-v-baseline {
  vertical-align: baseline;
}
.cd-v-baseline-i {
  vertical-align: baseline !important;
}

/** for sbb links that are displayed inline a row of buttons */
.cd-link-inline-button-row.sbb-link {
  margin-bottom: 12px;
  margin-top: 12px;
  height: 24px;
}

/** style a native button as frameless button (icons must _not_ use *sbbIcon directive!) */
.cd-frameless-button {
  border: none;
  background-color: transparent;
  padding: 0;
  display: inline-flex;
  align-items: center;

  sbb-icon {
    margin-left: pxToEm(6, $sizeFontDefault);
  }

  &:hover {
    color: $sbbColorCallToActionHover;
    cursor: pointer;
  }
}

.sbb-button, .sbb-link, .cd-link, .cd-frameless-button {
  &.cd-overlay-back-link {
    line-height: $lineHeightAlignedWithCloseButton;
  }
}

.cd-icon-button-placeholder {
  width: $buttonHeight;
  height: $buttonHeight;
}

.cd-icon-link {
  display: inline-flex;
  text-decoration: none;

  sbb-icon {
    position: relative;
    bottom: 7px;
  }
}

// set sbb icon and custom icon same size 24x24 (sbb icon default size = 24x25)
.cd-icon-small {
  width: $defaultIconSize;
  height: $defaultIconSize;
}

.cd-icon-small-placeholder {
  width: $defaultIconSize;
}

.cd-rotate-180 {
  transform: rotate(180deg);
}

.cd-sticky-top {
  position: sticky;
  position: -webkit-sticky; /* Safari */
  top: 0;
  z-index: 1;

  // add a little transparency to the background to make hidden elements visible so users can 'realize' sticky:
  background-color: color-mix(in srgb, var(--sbb-expansion-panel-background-color-open) 80%, transparent);
}

.cd-position-absolute {
  position: absolute;
}
