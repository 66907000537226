/**
 * Styles in here are (probably) deprecated or dirty fixes
 * and should be reviewed periodically and removed if possible.
 */
@import "src/app/styles/custom-reboot";

a {
  color: $colorText; // some links in the old UI are blue without this
}

a:hover {
  color: $colorRed125;
  fill: $colorRed125;
}

// fix bootstrap overwrite for links without href:
.manual-hover-link:hover {
  color: $colorCallToActionHover !important;
  cursor: pointer;
}

/** @deprecated bootstrap container */
.container {
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;

}
