@mixin color-factory($name, $color) {
  .color-#{$name} {
    color: $color;
  }
  .color-#{$name}-fill {
    fill: $color;
  }
  .color-#{$name}-bg {
    background-color: $color;
  }
  .color-#{$name}-border {
    border-color: $color;
  }
  .color-#{$name}-bg-important {
    background-color: $color !important;
  }
}

// States
@include color-factory('error', $colorError);
@include color-factory('warning', $cdColorWarning);
// make sure contrast on orange warning (=orange) background is good (in dark mode):
.color-warning-bg {
  color: $colorText;
}
@include color-factory('success', $cdColorSuccess);

// section highlighting
@include color-factory('section-light', $colorBlack5);

// shipment information
@include color-factory('sender', $colorSender);
@include color-factory('receiver', $colorReceiver);
@include color-factory('involvement', $colorInvolvement);
@include color-factory('none', $colorBlack10);
@include color-factory('grey', $colorBlack60);

@include color-factory('eta', $colorError);
@include color-factory('international', $colorBlack60);

// icon colors:
@mixin icon-color($name, $color) {
  .cd-icon-#{$name} {
    svg path {
      color: $color;
    }
  }
}

@include icon-color('grey', $colorBlack60);
@include icon-color('error', $colorError);
@include icon-color('warn', $cdColorWarning);
@include icon-color('white', $sbbColorWhite);
@include icon-color('success', $colorSuccess);

.cd-action-icon:hover, .cd-action-icon.active {
  cursor: pointer;

  svg * {
    color: var(--sbb-color-call-to-action-hover) !important;
  }
}
