// parts of "reboot" that we currently rely on:
.sbb-lean app-root :is(h1, h2, h3, h4, h5, h6):not(.m-inter-t, .m-interSmall-t, .m-inner-t, .m-innerSmall-t, .m-gutter-t, .m-inter-v, .m-interSmall-v, .m-inner-v, .m-innerSmall-v, .m-gutter-v) {
  margin-top: 0;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
