.cd-text-muted {
  color: $colorTextMuted;
}

.cd-text-unmuted {
  color: var(--sbb-color-text) !important;
}

.cd-text-display {
  font-size: 20px;
}

.cd-text-normal {
  font-family: $fontSbbRoman;
  font-weight: normal;
}

.cd-text-bold {
  font-family: $fontSbbBold;
  font-weight: normal;
}

.cd-text-italic {
  font-style: italic;
  font-weight: normal;
}

.cd-text-thin {
  font-family: $fontSbbThin;
  font-weight: normal;
}

.cd-text-strike {
  text-decoration: line-through;
}

.cd-text-underline {
  text-decoration: underline;
}

.cd-text-small {
  font-size: $sizeFontSmall;
  line-height: $lineHeightSmall;
}

/** use for "required" text in labels */
.cd-mandatory-label {
  margin-left: $gutterHeight;
}

.cd-empty-value {
  color: $colorTextMuted;
  font-family: $fontSbbLight;
}

.link-inactive {
  pointer-events: none;
  cursor: default;
  @extend .cd-text-muted;
}

.link-selected {
  color: $colorCallToAction;

  &.hover {
    color: $colorCallToActionHover;
  }
}

.cd-link-text-decoration-none {
  text-decoration: none;
}

.cd-pointer:hover {
  cursor: pointer
}

.cd-text-overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cd-nowrap {
  white-space: nowrap;
}

.cd-wrap {
  white-space: normal;
}

.cd-ws-pre {
  white-space: pre-line;
  word-break: break-word;
}

.cd-text-align-right {
  text-align: right;
}

.cd-text-align-center {
  text-align: center;
}

.cd-header {
  font-size: $sizeFontHeader;
  margin-bottom: $gutterHeight;
}

.cd-label {
  /*
   * duplicate from sacl
   * .sbb-form-field-label-wrapper (formfield.scss)
   */
    margin-bottom: pxToRem(3);
    padding-left: 0;
}

.cd-warning {
  color: $cdColorWarning;
  font-size: $sizeFontSmall;
  line-height: $lineHeightSmall;
}
