.separator-top {
  border-top: 1px $colorSeparationBorder solid;
}

.separator-bottom {
  border-bottom: 1px $colorSeparationBorder solid;
}

.cd-no-focus-outline {
  &:focus {
    outline: none !important;
  }
}
