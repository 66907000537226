@use '@sbb-esta/angular' as sbb;

:root:where(.sbb-lean) {
  --sbb-form-input-border-color: var(--sbb-color-graphite);
}

#cd-layout-filter,
#cd-layout-aside,
.cd-remove-accordion-border {
  .sbb-expansion-panel {
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;

    @extend .expansion-panel-compact;
  }
}

/* Textarea */
sbb-textarea.sbb-textarea {
  // ~2.5 rows:
  min-height: calc(#{$sizeFontBase} * #{$sizeLineHeightBase} * 2.5);
}

/* form warning */
.sbb-form-warning input {
  border-color: $cdColorWarning !important; // without 'important' border is grey when focused
  color: $cdColorWarning;
}

/* remove space for label */
.cd-form-field-no-label .sbb-form-field-label-wrapper {
  margin: 0;
}

/**
 * Component: Notification
 */
sbb-notification {

  // fix icon alignment, since business notifications don't have a fixed height
  .sbb-notification-icon-wrapper {
    align-self: center !important;
  }
}

/** Component: Tabs */
sbb-tab-body {
  margin: 0 !important;
  padding: 0 !important;
}

/** remove all `position: relative` from tabs to assure "position: absolute" is relative to parent scrollable */
.cd-tab-no-pos-relative {
  .sbb-tab-header {
    position: static;
  }

  .sbb-tab-body-wrapper {
    position: static;
    overflow: unset;

    .sbb-tab-body.sbb-tab-body-active {
      position: static;
      overflow-y: unset;
      overflow-x: clip; // assure sticky errors are actually sticky
    }

    .sbb-tab-body-content {
      overflow: unset;

      > * {
        position: static;
      }
    }
  }
}

.cd-tab-group {
  sbb-tab-header {
    $margin: 32px;
    margin-top: $margin;

    .sbb-tab-list {
      padding-left: $innerComponentSpacing;

      .sbb-tab-labels::before {
        content: "";
        width: $margin;
        position: absolute;
        bottom: 0;
        left: 0;
        border-bottom: var(--sbb-border-width) solid var(--sbb-tabs-border-color);
      }

      .sbb-tab-label {
        font-size: pxToEm($sbb-accordion-header-font-size) !important;
        line-height: $sbb-accordion-header-content-line-height;
        padding: $innerComponentSpacingSmall $sbbAccordionPaddingHorizontal !important;
      }
    }
  }

  .sbb-tab-body-wrapper {
    border: none;
  }
}

/**
 * Component: Expansion panel
 */
sbb-expansion-panel {
  text-align: left;

  // override opacity for disabled expansion panel
  .prevent-disabled-opacity {
    opacity: 1 !important;
  }
}

/**
 * Component: Context-Menu
 */
.icon-context-menu {
  vertical-align: bottom;
  margin-right: $contextIconMarginRight;
}

/**
 * Component: Checkbox-Panel (adapt public for business styles)
 */
sbb-checkbox-panel {
  .sbb-checkbox-radio-button-panel .sbb-checkbox-radio-button-panel-image:not(:empty) {
    height: $inputHeight - 6px; // account for paddings/margins
  }
}

/**
 * Workaround for https://github.com/sbb-design-systems/sbb-angular/issues/275 (necessary with "autoActiveFirstOption")
 * NOTE: apply as follows `<sbb-autocomplete class="active-fix"  classList="active-fix">` or `<sbb-select panelClass="active-fix">`.
 */
.active-fix sbb-option.sbb-active {
  color: $colorBlack !important;

  &:hover {
    color: $colorCallToAction !important;
  }
}

// currently only used for week order view (order-tables.component.html)
.sbb-tooltip-zero-padding .sbb-tooltip-close-button {
  top: $innerComponentSpacingSmall;
  right: $innerComponentSpacing;
}

/**
 * Use this in combination with `sbb-form-field-flexible-errors` to workaround for https://github.com/sbb-design-systems/sbb-angular/issues/879
 * This leaves space for a single line error message, but expands if the actual error message is multiline.
 * NOTE: To assure there is no layout shift for a single line error message, the container should use flex-column layout.
 */
.cd-form-field-expand-single-line-error {
  .sbb-form-field-error-wrapper {
    min-height: sbb.pxToRem(16);
    overflow: unset;
  }

  // fallback for manual usage of .sbb-form-field-wrapper without sbb-form-field/.sbb-form-field-error-wrapper (e.g. for sbb-checkbox):
  .sbb-form-field-wrapper:not(:has(.sbb-form-field-error-wrapper)) {
    padding-bottom: sbb.pxToRem(16);
  }
}

.cdk-overlay-container {
  z-index: 1000001; // needs to be raised above aside for waren and ladeeinheiten
}
