.sbb-expansion-panel.expansion-panel-compact {

  .sbb-expansion-panel-body {

    a:last-child {
      margin-bottom: 0;
    }
  }
}

/** by default top borders are removed in filter/aside, if the first accordion is not at to very top, we want to maintain the border: */
.sbb-expansion-panel.cd-force-top-border {
  border-top-width: 1px !important;
}
