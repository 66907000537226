/* You can add global styles to this file, and also import other style files */
@import 'variables';
@import '@sbb-esta/angular/typography.css';
@import "app/styles/layout";
@import "app/styles/border";
@import "app/styles/color";
@import "app/styles/typography";
@import "app/styles/table";
@import "app/styles/accordion";
@import "app/styles/checkbox";
@import "app/styles/cd-dl";
@import "app/styles/design-system-overrides";
@import "app/styles/curtain";
@import "app/styles/deprecated";
@import "app/styles/subnav";
@import "app/styles/print";
@import "app/styles/light-mode";
@import "app/styles/dark-mode";

.sbb-header-additional-content {
  z-index: 2; // assure notifications is above user menu (when closed)
}

:root {
  --scrollbar-offset: 4;
  --scrollbar-border-radius: 5px;
}
.copy-to-clipboard + .sbb-icon {
  height: 22px;
  width: 20px;
  opacity: $opacityMuted;
  padding-left: 3px;
  cursor: pointer;
}
.copy-to-clipboard-large + .sbb-icon {
  height: $inputHeight;
  width: $inputHeight;
  opacity: $opacityMuted;
  padding-left: $gutterHeight;
  padding-top: 3px;
  cursor: pointer;
}

.optimal-text-width {
  max-width: $optimalLineLength;
}

.column-label {
  font-family: $fontSbbBold;
}

.hidden {
  visibility: hidden;
}

.display-none {
  display: none;
}

app-generic-items-select-dialog {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
