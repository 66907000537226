/** in-product navigation */
.cd-sub-nav {
  a.cd-sub-nav-link {
    margin: 0 0 (1.5*$gutterHeight);
    display: block;
  }

  .cd-sub-nav-subsection {
    .cd-sub-nav-subsection-title {
      margin-bottom: $gutterHeight;
      margin-top: $innerComponentSpacing;

      font-family: $fontSbbLight;
      @extend .cd-text-muted;
    }
  }
}
